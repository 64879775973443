@import url('react-date-range/dist/styles.css');

body {
  margin: 0;
  padding: 0%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden; /* Hide scrollbars */
  background-image: url('./admin/assets/rumatrix2_01 1.png');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  color: #3f2f5b;
}

* {
  font-family: 'Inter', sans-serif;
}

input:-webkit-autofill,
.MuiInputBase-root:has(> input:-webkit-autofill) {
  background-color: #e8f0fe;
}
.MuiInputBase-root {
  border-radius: 10px;
  background: #ffffff;
}

::-webkit-scrollbar {
  width: 4px !important;
  height: 3px !important;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #9c9c9c91;
}
.element:hover::-webkit-scrollbar-thumb {
  background: #888;
}
.element:hover::-webkit-scrollbar-thumb {
  background: #888;
}

:root {
  --Old-colors-Line: rgba(255, 255, 255, 0.4 n);
  --Old-colors-Line: rgba(245, 246, 248, 1);
}
.error {
  color:red
}