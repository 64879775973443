*::-webkit-scrollbar {
    width: 4px !important;
  }

  *::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 5px;
  }

  *::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #FF7C52 0%, #A30C4C 100%);
    outline: none;
    border-radius: 5px;
  }
  