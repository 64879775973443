.optimization-mvrp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 20px;
}

.loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
}

.marker {
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border: 5px solid rgb(17, 226, 17);
  border-radius: 20px;
  background: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.P1 {
  border: 5px solid rgb(85, 79, 250);
}

.P2 {
  border: 5px solid rgb(85, 79, 250);
}

.P3 {
  border: 5px solid rgb(85, 79, 250);
}

.D1 {
  border: 5px solid rgb(42, 111, 250);
}

.D2 {
  border: 5px solid rgb(42, 111, 250);
}

.D3 {
  border: 5px solid rgb(42, 111, 250);
}

.V1-S {
  border: 5px solid rgb(17, 226, 17);
}

.V1-E {
  border: 5px solid rgb(250, 51, 25);
}

.V2-S {
  border: 5px solid rgb(17, 226, 17);
}

.V2-E {
  border: 5px solid rgb(250, 51, 25);
}

.hint {
  position: absolute;
  padding: 10px;
  border-radius: 10px;
  top: 10px;
  right: 10px;
  z-index: 10;
  width: 200px;
  background: #fff;
}
