@import 'rsuite/dist/rsuite.css';

/* Increase specificity to override default RSuite styles */
.rs-input-group,
.rs-input {
  border-radius: 20px; /* Adjust the value as needed */
  padding: 1px;
  text-align: center;
}
.rs-input-group.rs-input-group-inside {
  padding: 2px;
}
.rs-btn-primary,
.rs-btn-primary:disabled {
  background-color: #b94949;
  color: white;
  min-width: 100px;
  border-radius: 20px;
}
.rs-btn-primary:hover {
  background-color: #b94949;
  color: white;
  min-width: 100px;
  border-radius: 20px;
}
.rs-input-group:not(.rs-input-group-disabled):hover,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:focus-within {
  border-color: black;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #b94949;
}
.rs-calendar-table-cell-in-range::before,
.rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-content:focus-visible {
  background-color: #ffd6d6;
}
.rs-calendar-table-cell-content:focus-visible {
  border-color: #ffd6d6;
}
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  background-color: #ffd6d6;
  border: 1px solid #b94949;
  box-shadow: 1px 1px #b94949;
}
.rs-picker-popup .rs-calendar-table-cell:focus-visible,
.rs-picker-popup .rs-calendar-table-cell:hover,
.rs-picker-popup
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  background-color: #ffd6d6;
  color: #b94949;
}
