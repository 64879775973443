@font-face {
  font-family: 'Lemon Milk';
  src: url('./LEMONMILK-Regular.otf') format('truetype'); 
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Lemon Milk';
  src: url('./LEMONMILK-Medium.otf') format('truetype'); 
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Lemon Milk';
  src: url('./LEMONMILK-Bold.otf') format('truetype'); 
  font-weight: 700;
  font-style: normal;
} 