.switchLabel {
  width: 75px;
  height: 20px;
  background: white;
  position: relative;
  display: block;
  border-radius: 100px;
  cursor: pointer;
  transition: 0.5s;
  transform: translateY(-12px);
}

.switchLabel:after {
  content: 'InActive';
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Century Gothic, sans-serif;
  width: 40px;
  height: 15px;
  position: absolute;
  top: 3px;
  left: 4px;
  background: #8d8888;
  border-radius: 100px;
  transition: 0.5s;
}

.switcher {
  width: 0;
  height: 0;
  visibility: hidden;
}

.switcher:checked + label::after {
  content: 'Active';
  transform: translateX(28px);
  background: #ff7676;
}
