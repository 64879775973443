.list1::marker {
  font-size: 2em;
  color: #4cee70;
  border-radius: 4px;
}
.list2::marker {
  border-radius: 4px;
  font-size: 2em;
  color: #ed593f;
}
.list3::marker {
  border-radius: 4px;
  font-size: 2em;
  color: #d94f4f;
}
