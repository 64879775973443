.switchLabel {
  width: 51px;
  height: 22px;
  background: white;
  position: relative;
  display: block;
  border-radius: 100px;
  cursor: pointer;
  transition: 0.5s;
  transform: translateY(-12px);
  border: 1px solid black;
}

.switchLabel:after {
  content: 'OFF';
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Century Gothic, sans-serif;
  width: 25px;
  height: 15px;
  position: absolute;
  top: 3px;
  left: 4px;
  background: #8d8888;
  border-radius: 100px;
  transition: 0.5s;
}

.switcher {
  width: 0;
  height: 0;
  visibility: hidden;
}

.switcher:checked + label::after {
  content: 'ON';
  transform: translateX(18px);
  background: #ff7676;
}

.container {
}
